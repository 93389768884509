<template>
	<CreateDialog :dialog="createDialog" v-on:close="createDialog = false">
		<template v-slot:title>
			<span v-if="!proformaDetail?.uuid">Create Proforma</span>
			<span v-if="proformaDetail?.uuid">{{ proformaDetail.title }}</span>
			<span v-if="proformaDetail?.barcode" class="orange--text text--darken-4 barcode-font-size">
				#{{ proformaDetail?.barcode }}</span
			>
		</template>
		<template v-slot:body>
			<v-sheet style="height: calc(100vh - 188px)">
				<v-row>
					<v-col md="12">
						<v-form ref="proformaForm">
							<v-row dense v-if="!proformaUpdate">
								<v-col md="9">
									<perfect-scrollbar
										:options="{ suppressScrollX: true }"
										class="scroll custom-box-top-inner-shadow"
										style="position: relative"
									>
										<v-row>
											<v-col md="3">
												<label for="title" class="btx-label font-level-2-bold">Milestone</label>
											</v-col>
											<v-col md="9">
												<ShowValue
													class="font-level-2-bold"
													:object="milestone"
													object-key="phase"
													label="Milestone Title"
												>
												</ShowValue>
											</v-col>
											<v-col md="3">
												<label for="amount" class="btx-label mt-2">Milestone Amount</label>
											</v-col>
											<v-col md="3" class="d-flex align-items-center">
												<span class="font-level-2" v-if="milestone.amount">
													{{ formatMoney(milestone.amount) }}</span
												>
												<span class="font-level-2" v-else> {{ formatMoney(0) }}</span>
											</v-col>
											<v-col md="3" class="text-right">
												<label for="milestone_balance" class="btx-label mt-2">Milestone Balance</label>
											</v-col>
											<v-col md="3" class="d-flex align-items-center">
												<span class="font-level-2" v-if="milestoneBalance">
													{{ formatMoney(milestoneBalance) }}</span
												>
												<span class="font-level-2" v-else> {{ formatMoney(0) }}</span>
											</v-col>
											<v-col md="3">
												<label for="name" class="btx-label mt-4">Name</label>
											</v-col>
											<v-col md="9">
												<TextInput
													id="name"
													hide-details
													placeholder="Name"
													v-model="proforma.title"
													:disabled="pageLoading"
													:loading="pageLoading"
												>
												</TextInput>
											</v-col>

											<v-col md="3">
												<label for="proforma_amount" class="btx-label mt-2">Proforma Amount</label>
											</v-col>
											<v-col md="9">
												<PriceInput
													id="proforma_amount"
													type="number"
													hideSpinButtons
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													placeholder="Amount"
													prepend-inner-icon="mdi-currency-usd"
													v-model="proforma.amount"
													class="mt-0"
													:rules="[vrules.required(proforma.amount, 'Proforma Amount')]"
													:class="{
														required: !proforma.amount,
													}"
													@keyup="proformaAmountChange"
												></PriceInput>
											</v-col>
										</v-row>
										<v-row dense>
											<v-col md="3">
												<label for="attachment" class="btx-label mt-4">Attachment</label>
											</v-col>
											<v-col md="9">
												<FileUpload
													hide-details
													id="attachment"
													:allowAddMore="false"
													v-model="proforma.attachment"
												>
												</FileUpload>
											</v-col>
											<v-col md="3">
												<label for="certificate" class="btx-label mt-4">Certificate</label>
											</v-col>
											<v-col md="9">
												<FileUpload
													hide-details
													id="certificate"
													:allowAddMore="false"
													v-model="proforma.certificate"
												>
												</FileUpload>
											</v-col>
										</v-row>
										<!-- </template> -->
										<template v-if="!pageLoading">
											<template v-if="lineItemsList.length">
												<div
													class="middle-header-background d-flex align-items-center justify-content-between my-2"
												>
													<p class="mb-2 px-4">
														Line items<br />
														<span>
															<v-icon small>mdi-progress-question</v-icon> Specify the Line Items for your
															Quotation</span
														>
													</p>
												</div>
												<v-simple-table
													v-if="lineItemsList.length"
													class="bt-table listing-table table-head-sticky"
												>
													<thead>
														<tr>
															<!-- <th class="simple-table-th"> -->
															<!-- <v-checkbox dense @click="selectAllQuotations"></v-checkbox> -->
															<!-- </th> -->
															<th class="simple-table-th">#</th>
															<th>Quote #</th>
															<th class="simple-table-th">Name</th>
															<th class="simple-table-th">Price</th>
															<!--  <th class="simple-table-th">QTY Ordered</th> -->
															<th class="simple-table-th">UOM</th>
															<th class="simple-table-th">QTY</th>
															<!-- <th max-width="150px" class="simple-table-th">Deliverable</th> -->
															<th class="simple-table-th">QTY to be delivered</th>
															<th class="simple-table-th">QTY delivered</th>
															<th class="simple-table-th">Gap</th>
														</tr>
													</thead>
													<tbody v-if="lineItemsList.length">
														<tr class="cursor-pointer" v-for="(row, index) in lineItemsList" :key="index">
															<td>{{ index + 1 }}</td>
															<td>
																<Chip
																	tooltip
																	tooltip-text="Quotation"
																	small
																	:text="row.quotation_barcode"
																	color="blue darken-4"
																>
																</Chip>
															</td>
															<td>
																<ShowValue :object="row" object-key="line_item_name" label="Name"> </ShowValue>
															</td>
															<td>
																<ShowValue :object="row" object-key="line_item_total_formatted" label="Price">
																</ShowValue>
															</td>
															<td>
																<ShowValue :object="row" object-key="line_item_uom" label="uom"> </ShowValue>
															</td>
															<td>
																<!-- <ShowValue :object="row" object-key="line_item_quantity" label="Quantity">
															</ShowValue> -->
																<v-tooltip top>
																	<template v-slot:activator="{ on, attrs }">
																		<span v-bind="attrs" v-on="on"> {{ row.all_milestones_qty_gap }}</span>
																	</template>
																	<span>All Milestone Qty Gap</span></v-tooltip
																>/<v-tooltip top>
																	<template v-slot:activator="{ on, attrs }">
																		<span v-bind="attrs" v-on="on"> {{ row.line_item_quantity }}</span>
																	</template>
																	<span>Line Item Qty</span>
																</v-tooltip>
																<!-- {{ row.all_milestones_qty_gap }}/{{ row.line_item_quantity }} -->
																<!-- {{ row.total_qty_balance }}/{{ row.line_item_quantity }} -->
															</td>
															<td width="80px">
																<ShowValue
																	:object="row"
																	object-key="qty_to_be_delivered"
																	label="Quantity To Be Delivered"
																>
																</ShowValue>
															</td>

															<td width="60px" class="td_column cursor-default simple-table-td">
																<TextInput
																	type="number"
																	hideSpinButtons
																	hide-details
																	placeholder="QTY Delivered"
																	@keypress="qtyDeliveredKeyPress"
																	@keyup="qtyDelivered(index, row.qty_deliver, row.gap)"
																	v-model.number="row.qty_deliver"
																>
																</TextInput>
															</td>
															<td class="td_column cursor-default simple-table-td">
																<!-- {{ row.qty_to_be_delivered - (row.qty_deliver + row.already_delivered_qty) }} / -->
																<v-tooltip top>
																	<template v-slot:activator="{ on, attrs }">
																		<span v-bind="attrs" v-on="on">
																			{{
																				row.qty_to_be_delivered - (row.qty_deliver + row.already_delivered_qty)
																			}}</span
																		>
																	</template>
																	<span>Gap</span> </v-tooltip
																>/<v-tooltip top>
																	<template v-slot:activator="{ on, attrs }">
																		<span v-bind="attrs" v-on="on">{{ row.qty_to_be_delivered }}</span>
																	</template>
																	<span>Qty To Be Delivered</span>
																</v-tooltip>
															</td>
														</tr>
													</tbody>
													<tfoot v-else>
														<tr>
															<td colspan="11">
																<p class="m-0 row-not-found text-center py-3">
																	<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																	Uhh... There are no task at the moment.
																</p>
															</td>
														</tr>
													</tfoot>
												</v-simple-table>
											</template>
										</template>
										<template v-else>
											<div class="mx-20 my-40 text-center">
												<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
											</div>
										</template>
									</perfect-scrollbar>
								</v-col>
							</v-row>
							<v-row v-if="proformaUpdate">
								<v-col md="9">
									<v-row dense>
										<v-col md="3">
											<label for="proforma_amount" class="btx-label mt-2 font-level-2">Proforma Amount</label>
										</v-col>
										<v-col md="3" class="mt- d-flex align-items-center">
											<span class="font-level-2" v-if="proformaDetail.amount">
												{{ formatMoney(proformaDetail.amount) }}</span
											>
											<span class="font-level-2" v-else> {{ formatMoney(0) }}</span>
										</v-col>
										<v-col md="3" class="text-right">
											<label for="proforma_amount" class="btx-label mt-2 font-level-2">Proforma Balance</label>
										</v-col>
										<v-col md="3" class="mt- d-flex align-items-center">
											<span class="font-level-2">
												{{ formatMoney(proformaDetail.amount - proforma.approve_amount) }}</span
											>
											<!-- <span class="font-level-2" v-else> {{ 	(0)
											}}</span> -->
										</v-col>
										<v-col md="3">
											<label for="proforma_amount" class="btx-label mt-2 font-level-2"
												>Invoice Collected Amount
											</label>
										</v-col>
										<v-col md="3" class="mt- d-flex align-items-center">
											<span class="font-level-2" v-if="proformaDetail.invoice_amount">
												{{ formatMoney(proformaDetail.invoice_amount) }}</span
											>
											<span class="font-level-2" v-else> {{ formatMoney(0) }}</span>
										</v-col>
										<v-col md="3" class="btx-label text-right">
											<label for="approve_amount" class="btx-label mt-4">Approve Amount</label>
											<!-- {{ proformaDetail.amount - proforma.approve_amount }} -->
										</v-col>
										<v-col md="3">
											<PriceInput
												id="approve_amount"
												hide-details
												hideSpinButtons
												placeholder="Approve Amount"
												v-model="proforma.approve_amount"
												:disabled="pageLoading"
												@keyup="proformaApproveAmount"
												@change="approveAmountChange"
												:loading="pageLoading"
											>
											</PriceInput>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-col md="9">
								<div v-if="proformaUpdate">
									<v-expansion-panels
										flat
										accordion
										tile
										class="mt-4 transparent-panels"
										v-model="updateReturnLineItem"
									>
										<v-expansion-panel>
											<v-expansion-panel-header>
												<!-- <v-checkbox hide-details v-model="update_return_line_item"
													label="Update Return Line Items"></v-checkbox> -->
												Update Return Line Items
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<div v-if="updateLineItem.length" class="overflow-y return-line-items">
													<v-simple-table class="bt-table listing-table table-head-sticky" width="100%">
														<thead>
															<tr>
																<!-- <th class="simple-table-th"> -->
																<!-- <v-checkbox dense @click="selectAllQuotations"></v-checkbox> -->
																<!-- </th> -->
																<th class="simple-table-th">#</th>
																<th>Quote #</th>
																<th class="simple-table-th">Name</th>
																<th class="simple-table-th">Price</th>
																<!--  <th class="simple-table-th">QTY Ordered</th> -->
																<th class="simple-table-th">UOM</th>
																<th class="simple-table-th">QTY</th>
																<!-- <th max-width="150px" class="simple-table-th">Deliverable</th> -->
																<th class="simple-table-th">QTY to be delivered</th>
																<th class="simple-table-th">QTY delivered</th>
																<th class="simple-table-th">Return</th>
																<th class="simple-table-th">Gap</th>
															</tr>
														</thead>
														<tbody v-if="updateLineItem.length">
															<tr class="cursor-pointer" v-for="(row, index) in updateLineItem" :key="index">
																<td>{{ index + 1 }}</td>
																<td>
																	<Chip
																		tooltip
																		tooltip-text="Quotation"
																		small
																		:text="row.quotation_barcode"
																		color="blue darken-4"
																	>
																	</Chip>
																</td>
																<td>
																	<ShowValue :object="row" object-key="line_item_name" label="Name"> </ShowValue>
																</td>
																<td>
																	<ShowPrice :object="row" object-key="line_item_total" label="Price"> </ShowPrice>
																</td>
																<td>
																	<ShowValue :object="row" object-key="line_item_uom" label="uom"> </ShowValue>
																</td>
																<td>{{ row.all_milestones_qty_gap }}/{{ row.line_item_quantity }}</td>
																<td width="80px">
																	<ShowValue
																		:object="row"
																		object-key="qty_to_be_delivered"
																		label="Quantity To Be Delivered"
																	>
																	</ShowValue>
																</td>

																<td width="60px" class="td_column cursor-default simple-table-td">
																	<ShowValue :object="row" object-key="delivered_qty" label="Quantity Deliver">
																	</ShowValue>
																</td>
																<td class="td_column cursor-default simple-table-td" width="150px">
																	<TextInput
																		type="number"
																		hideSpinButtons
																		hide-details
																		id="return"
																		placeholder="Return"
																		v-model.number="row.return_qty"
																		@keyup="returnQtyChange(index, row.delivered_qty, row.return_qty)"
																	>
																	</TextInput>
																</td>
																<td class="td_column cursor-default simple-table-td">
																	{{ row.gap + row.return_qty }}
																	<span v-if="row.qty_to_be_delivered">/{{ row.qty_to_be_delivered }}</span>
																</td>
															</tr>
														</tbody>
														<tfoot v-else>
															<tr>
																<td colspan="11">
																	<p class="m-0 row-not-found text-center py-3">
																		<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																		Uhh... There are no task at the moment.
																	</p>
																</td>
															</tr>
														</tfoot>
													</v-simple-table>
													<v-row>
														<v-col md="3" class="py-0">
															<label for="Remark" class="btx-label mt-4">Remark</label>
														</v-col>
														<v-col md="9" class="py-0">
															<TextAreaInput
																placeholder="Remark"
																v-model="returnRemark"
																:disabled="pageLoading"
																id="Remark"
																:loading="pageLoading"
															></TextAreaInput>
														</v-col>
													</v-row>
												</div>
												<div v-else>
													<h6 class="text-center">No Line Items To Return</h6>
												</div>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</v-expansion-panels>
									<v-expansion-panels
										flat
										tile
										class="mt-4 transparent-panels"
										v-model="generateInvoice"
										@change="generateInvoiceChange"
									>
										<v-expansion-panel :disabled="proformaDetail.disable_invoice ? true : false">
											<v-expansion-panel-header>
												<v-checkbox
													hide-details
													:disabled="proformaDetail.disable_invoice ? true : false"
													v-model="do_invoice"
													label="Do Invoice"
													>Generate Invoice</v-checkbox
												>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<v-form ref="invoiceFrom">
													<v-row dense>
														<v-col md="12">
															<perfect-scrollbar
																:options="{ suppressScrollX: true }"
																class="scroll custom-box-top-inner-shadow"
																style="position: relative"
															>
																<v-row class="px-1">
																	<v-col md="3">
																		<label for="invoice_title" class="mt-4 btx-label">Title</label>
																	</v-col>
																	<v-col md="9">
																		<TextInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="invoice_title"
																			placeholder="Invoice Title"
																			v-model="invoice.title"
																		>
																		</TextInput>
																	</v-col>
																	<v-col md="3">
																		<label for="invoice_no" class="mt-4 btx-label">Invoice no</label>
																	</v-col>
																	<v-col md="3">
																		<TextInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="invoice_no"
																			placeholder="Invoice No"
																			v-model="invoice.invoice_no"
																		>
																		</TextInput>
																	</v-col>
																	<v-col md="3" class="text-right btx-label">
																		<label for="invoice_date" class="mt-4 required">Invoice Date</label>
																	</v-col>
																	<v-col md="3">
																		<DatePicker
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="invoice_date"
																			v-model="invoice.invoice_date"
																			:rules="[vrules.required(invoice.invoice_date, 'Date')]"
																			:class="{
																				required: !invoice.invoice_date,
																			}"
																		></DatePicker>
																	</v-col>
																	<v-col md="3">
																		<label for="invoice_amount" class="btx-label mt-4">Invoice Amount</label>
																	</v-col>
																	<v-col md="3" class="d-flex align-items-center">
																		<span class="font-level-2" v-if="proforma.approve_amount">
																			{{ formatMoney(proforma.approve_amount) }}</span
																		>
																		<span class="font-level-2" v-else> {{ formatMoney(0) }}</span>
																	</v-col>
																	<v-col md="3" class="text-right">
																		<label for="invoice_amount" class="btx-label mt-3"
																			>Collected Amount Till Date</label
																		>
																	</v-col>
																	<v-col md="3" class="d-flex align-items-center">
																		<span class="font-level-2" v-if="proformaDetail.invoices_collected_till_date">
																			{{ formatMoney(proformaDetail.invoices_collected_till_date) }}</span
																		>
																		<span class="font-level-2" v-else> {{ formatMoney(0) }}</span>
																	</v-col>
																	<v-col md="3">
																		<label for="balance" class="mt-3 btx-label">To Be Collected</label>
																	</v-col>
																	<v-col md="3" class="d-flex align-items-center">
																		<!-- {{proforma}} -->
																		<span v-if="proforma.approve_amount">
																			{{ formatMoney(proformaDetail.balance - invoice.collected_amount) }}</span
																		>
																		<span class="font-level-2" v-else> {{ formatMoney(0) }}</span>
																	</v-col>
																	<v-col md="3" class="btx-label text-right">
																		<label for="collected_amount" class="btx-label mt-4 required">Collected</label>
																	</v-col>
																	<v-col md="3" class="d-flex align-items-center">
																		<PriceInput
																			id="collected_amount"
																			hide-details
																			type="number"
																			hideSpinButtons
																			placeholder="Collected Amount"
																			:rules="[vrules.required(invoice.collected_amount, 'Collected Amount')]"
																			:class="{
																				required: !invoice.collected_amount,
																			}"
																			v-model="invoice.collected_amount"
																			:loading="pageLoading"
																			@keyup="invoiceCollectedAmountChange"
																		>
																		</PriceInput>
																	</v-col>
																</v-row>
																<v-row>
																	<v-col md="3">
																		<label for="attachment" class="mt-4 btx-label">Attachment</label>
																	</v-col>
																	<v-col md="9">
																		<FileUpload hide-details id="attachment" v-model="invoice.attachment">
																		</FileUpload>
																	</v-col>
																	<v-col md="3">
																		<label for="remark" class="mt-4 btx-label">Remark</label>
																	</v-col>
																	<v-col color="9">
																		<TextAreaInput
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			id="remark"
																			placeholder="Remark"
																			v-model="invoice.remark"
																		>
																		</TextAreaInput>
																	</v-col>
																</v-row>
															</perfect-scrollbar>
														</v-col>
													</v-row>
												</v-form>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</v-expansion-panels>
								</div>
							</v-col>
						</v-form>
					</v-col>
				</v-row>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$router.go(-1)"> Close </v-btn>
			<v-btn @click="createProforma()" class="white--text" depressed color="blue darken-4" tile>
				Save
			</v-btn>
		</template>
	</CreateDialog>
</template>
<script>
// import { CreateProforma, /* updateProforma */ } from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import Dialog from "@/view/components/Dialog";
import CreateDialog from "@/view/components/CreateDialog";
import TextInput from "@/view/components/TextInput";
import FileUpload from "@/view/components/FileUpload";
import TextAreaInput from "@/view/components/TextAreaInput";
import {
	UPDATE_PROFORMA,
	GET_PROFORMA_BY_PROJECT,
	CREATE_PROFORMA,
} from "@/core/services/store/proforma.module.js";
import { createInvoice } from "@/core/lib/project.lib";
import { getMilestoneByUUID, GetProject /* getProforma */ } from "@/core/lib/project.lib";
// import TextAreaInput from "@/view/components/TextAreaInput";
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import Chip from "@/view/components/Chip";
import PriceInput from "@/view/components/PriceInput";
import DatePicker from "@/view/components/DatePicker";
// import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import { mapGetters } from "vuex";
import { getData, deleteData } from "@/core/services/local.service";
import { mapGetters } from "vuex";
import {
	GET_MILESTONE_DELIVERABLE,
	GET_PROFORMA_By_UUID,
} from "@/core/services/store/proforma.module.js";
import ListingMixin from "@/core/mixins/listing.mixin.js";
export default {
	name: "create-proforma",
	title: "Create Proforma",
	mixins: [ListingMixin],
	components: {
		// Dialog,
		// AutoCompleteInput,
		DatePicker,
		ShowValue,
		TextInput,
		FileUpload,
		TextAreaInput,
		ShowPrice,
		Chip,
		CreateDialog,
		PriceInput,
	},

	data() {
		return {
			createDialog: true,
			isDeliveryExistDialog: false,
			contact_person: [],
			proformaBalance: null,
			uuid: null,
			contentLoading: false,
			formValid: true,
			do_invoice: false,
			quantity_to_delivered: null,
			proforma_qty_to_be_delivered: null,
			proforma_barcode: "PF-001",
			lineItemsList: [],
			updateReturnLineItem: null,
			update_return_line_item: false,
			generateInvoice: false,
			proformaDetail: {
				amount: null,
				proforma_title: null,
				proforma_price: null,
				proforma_amount: null,
				// milestone_price:3000,
				barcode: null,
				milestone_price: null,
				balance: null,
			},
			proforma: {
				proforma_price: null,
				approve_amount: null,
				title: null,
				proforma_amount: null,
				amount: null,
				attachment: [],
				certificate: null,
				invoice_amount: null,
				milestone_balance: null,
				milestone_amount: null,
			},
			invoice: {
				id: null,
				invoice_invoice_status: null,
				invoice_date: null,
				amount: null,
				title: null,
				attachment: [],
				// amount: null,
				collected_amount: null,
				balance: null,
			},
			updateLineItem: [
				// {
				// 	quotation_barcode: "QA-001",
				// 	qty_to_be_delivered: 1,
				// 	line_item_quantity: 2,
				// 	line_item_uom: 1,
				// 	line_item_total_formatted: 2,
				// 	line_item_name: "Line Items 1",
				// 	return: 4,
				// },
				// {
				// 	quotation_barcode: "QA-001",
				// 	qty_to_be_delivered: 1,
				// 	line_item_quantity: 2,
				// 	line_item_uom: 1,
				// 	line_item_total_formatted: 2,
				// 	line_item_name: "Line Items 1",
				// 	return: 4,
				// },
			],
			projectUUID: null,
			mileUUID: null,
			project: null,
			milestone: {
				phase: null,
				amount: null,
				milestone_balance: null,
			},
			proformaUpdate: false,
			returnRemark: null,
			// isLineItemExist: null,
			proformaUUID: null,
			statusList: [
				{
					value: 1,
					text: "PAID",
				},
				{
					value: 2,
					text: "UNPAID",
				},
				{
					value: 3,
					text: "PARTIAL-PAID",
				},
			],
		};
	},
	methods: {
		async createProforma() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.proformaForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.proformaForm.validate()) {
				return false;
			}
			if (!this.$route.query.proformaUUID) {
				const anyQTyDelivered = this.lineItemsList.some((ele) => ele.qty_deliver);
				if (!this.$route.query?.noDeliverable && !anyQTyDelivered) {
					const errors = [
						{
							message: "At least deliver one quantity!",
							timeout: 2000,
							model: true,
						},
					];
					_this.$store.commit(SET_ERROR, _this.errors.concat(errors));
					return false;
				}
			}
			try {
				if (_this.proformaUpdate) {
					let lineItemWithReturnValue = [];
					if (this.updateLineItem.length) {
						lineItemWithReturnValue = this.updateLineItem.filter((ele) => ele.return_qty);
					}
					let body = null;
					if (this.updateReturnLineItem === 0) {
						body = {
							uuid: _this.proformaDetail.uuid,
							params: {
								return_items: lineItemWithReturnValue,
								amount_approved: _this.proforma.approve_amount,
								return_items_remark: this.returnRemark,
							},
						};
					} else {
						body = {
							uuid: _this.proformaDetail.uuid,
							params: {
								amount_approved: _this.proforma.approve_amount,
							},
						};
					}

					await _this.$store.dispatch(UPDATE_PROFORMA, body);
					if (_this.do_invoice) {
						const params = {
							proforma: _this.proformaDetail.id,
							project: _this.project.id,
							invoice_no: _this.invoice.invoice_no,
							title: _this.invoice.title,
							date: _this.invoice.invoice_date,
							amount: _this.invoice.amount,
							collected_amount: _this.invoice.collected_amount,
							remark: _this.invoice.remark,
							files: _this.invoice.attachment,
						};
						if (!_this.invoice.invoice_date) {
							const errors = [
								{
									message: "Invoice date is required!",
									timeout: 2000,
									model: true,
								},
							];
							_this.$store.commit(SET_ERROR, _this.errors.concat(errors));
							return false;
						}
						if (!_this.invoice.collected_amount) {
							const errors = [
								{
									message: "Collected amount is required!",
									timeout: 2000,
									model: true,
								},
							];
							_this.$store.commit(SET_ERROR, _this.errors.concat(errors));
							return false;
						}
						await createInvoice(params);
					}
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Proforma has been updated." },
					]);
				} else {
					const newLineItems = this.lineItemsList.filter((ele) => ele.qty_deliver);
					let params = {
						title: _this.proforma.title,
						project: _this.project.id,
						project_milestone: this.milestone.id,
						customer: _this.project.contact,
						amount: _this.proforma.amount,
						files: _this.proforma.attachment,
						certificate: _this.proforma.certificate[0],
						// invoice_amount: this.proforma.invoice_amount,
						deliverables: newLineItems,
					};
					if (this.$route.query?.noDeliverable) {
						params = { performa_without_delivery: true, ...params };
					}
					await this.$store.dispatch(CREATE_PROFORMA, params);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Proforma has been created." },
					]);
				}
				deleteData("milestone_line_items");
				_this.createDialog = false;
				_this.proforma = {
					title: null,
					amount: null,
					attachment: [],
					certificate: [],
					invoice_amount: null,
				};
				this.$router.go(-1);
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error.data.message }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async loadProforma() {
			this.pageLoading = true;
			try {
				await this.$store.dispatch(GET_PROFORMA_BY_PROJECT, this.project.uuid);
				this.pageLoading = false;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.pageLoading = false;
			}
		},
		async getProject() {
			try {
				this.pageLoading = true;
				const project = await GetProject(this.$route.params.uuid);
				this.project = project[0];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async getMilestone() {
			const _this = this;
			try {
				_this.pageLoading = false;
				const milestone = await getMilestoneByUUID(this.$route.query.mileUUID);
				_this.milestone = milestone[0];
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		proformaAmountKeyUp() {
			this.proformaBalance = this.milestone.amount - this.proforma.proforma_amount;
		},
		returnQtyChange(index, qty_to_be_delivered, return_qty) {
			if (return_qty > qty_to_be_delivered) {
				this.updateLineItem[index].return_qty = qty_to_be_delivered;
			}
		},
		qtyDelivered(index, qty_deliver, gap) {
			if (qty_deliver > gap) {
				this.lineItemsList[index].qty_deliver = gap;
			}
		},
		qtyDeliveredKeyPress($event) {
			if ($event.key === "e") {
				$event.preventDefault();
			}
		},
		async milestoneDeliverable() {
			let milestone_line_items = getData("milestone_line_items");
			if (milestone_line_items) {
				const params = {
					quotation: null,
					filter_deliverable_ids: milestone_line_items,
				};
				try {
					this.pageLoading = true;
					let { deliverables } = await this.$store.dispatch(GET_MILESTONE_DELIVERABLE, {
						uuid: this.$route.query.mileUUID,
						params: params,
					});
					deliverables.forEach((ele) => {
						if (!ele?.gap) {
							ele.qty_deliver = 0;
						} else {
							ele.qty_deliver = ele?.gap;
						}
					});

					this.lineItemsList = deliverables;
				} catch (error) {
					this.logError(error);
				} finally {
					this.pageLoading = false;
				}
			}
		},
		async initProforma() {
			try {
				this.pageLoading = true;
				const data = await this.$store.dispatch(GET_PROFORMA_By_UUID, this.$route.query.proformaUUID);
				// filtering those delivered items those delivered_qty
				const lineItemsWithDeliverQty = data[0].proforma_delivered_items.filter(
					(ele) => ele.delivered_qty
				);
				this.updateLineItem = lineItemsWithDeliverQty;
				this.proformaDetail = data[0];
				this.proforma.approve_amount = this.proformaDetail.approved_amount;
				this.invoice.amount = this.proformaDetail.balance;
			} catch (error) {
				this.logError(error);
			} finally {
				this.pageLoading = false;
			}
		},
		async proformaAmountChange() {
			const _this = this;
			if (_this.proforma.amount > _this.milestone.milestone_balance) {
				_this.$store.commit(SET_ERROR, [
					{
						model: true,
						message: "Proforma amount can not be greater than remaining milestone balance!",
					},
				]);
				_this.proforma.amount = _this.milestone.milestone_balance;
			}
		},
		async generateBarcode() {
			this.genrateBarcode("performa-invoice").then((output) => {
				this.proformaDetail.barcode = output;
			});
		},
		proformaApproveAmount() {
			if (this.proforma.approve_amount > this.proformaDetail.amount) {
				this.proforma.approve_amount = this.proformaDetail.amount;
			}
			this.invoice.collected_amount = 0;
			if (this.proforma.approve_amount > 0) {
				this.invoice.amount = this.proforma.approve_amount;
			} else {
				this.invoice.amount = this.proformaDetail.balance;
			}
		},
		visibilityReturnLinePanel() {
			if (this.proforma.approve_amount < this.proformaDetail.amount) {
				this.updateReturnLineItem = 0;
			} else {
				this.updateReturnLineItem = false;
			}
		},
		approveAmountChange() {
			this.visibilityReturnLinePanel();
			if (this.proformaDetail.invoice_amount > this.proforma.approve_amount) {
				this.proforma.approve_amount = this.proformaDetail.invoice_amount;
			}
		},

		invoiceCollectedAmountChange() {
			if (this.invoice.collected_amount > this.proformaDetail.balance) {
				this.invoice.collected_amount = this.proformaDetail.balance;
			}
		},
		doInvoiceChange() {
			if (this.do_invoice && !this.proforma.approve_amount) {
				this.proforma.approve_amount = this.proformaDetail.balance;
			} else {
				this.proforma.approve_amount = null;
			}
		},
		generateInvoiceChange() {
			if (this.generateInvoice === 0) {
				this.do_invoice = true;
			} else {
				this.do_invoice = false;
			}
		},
	},
	computed: {
		...mapGetters(["errors"]),
		invoiceBalance: {
			get() {
				return (
					this.proforma.approve_amount -
					(this.invoice.collected_amount + this.proformaDetail.invoice_amount)
				);
			},
			// setter
			set(newValue) {
				return newValue;
			},
		},
		milestoneBalance: {
			get() {
				if (this.milestone?.milestone_balance - this.proforma?.amount) {
					return this.milestone?.milestone_balance - this.proforma.amount;
				} else {
					return 0;
				}
			},
			// setter
			set(newValue) {
				return newValue;
			},
		},
		proformaBalance1: {
			get() {
				return this.proformaDetail?.amount - this.proformaDetail.balance;
			},
			set() {
				return this.proformaDetail?.amount - this.proforma.approve_amount;
			},
		},
	},
	async mounted() {
		this.proformaUUID = this.$route.query.proformaUUID;
		if (this.$route.query.proformaUUID) {
			this.proformaUpdate = true;
		}
		if (!this.$route.query.proformaUUID) {
			await this.generateBarcode();
			await this.milestoneDeliverable();
		}
		if (this.$route.query.proformaUUID) {
			await this.initProforma();
			this.visibilityReturnLinePanel();
		}
		await this.getMilestone();
		await this.getProject();
	},
};
</script>
